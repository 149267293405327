import { Box, Flex, Spacer } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect } from "react";
import Datatable from "../../components/DataTable";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";

const ValidationList = () => {
  const fetchValidationListItems = useStore(
    (state) => state.fetchAllValidationListItems
  );
  const posValidationListItems = useStore(
    (state) => state.validation_list_items
  );

  const isLoading = useStore((state) => state.loading)

  useEffect(() => {
    fetchValidationListItems();
  }, [fetchValidationListItems]);

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Validation List
          </Box>
        </Flex>
        <Spacer />
      </Flex>
      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              Header: "Pan ID",
              accessorKey: "pan_id",
            },
            {
              Header: "Flag",
              accessorKey: "flag",
            },
            {
              Header: "Balance",
              accessorKey: "balance",
            },
            {
              Header: "Timestamp",
              accessorKey: "timestamp",
            },
          ]}
          data={posValidationListItems}
          dataTableHeading={"Validation List"}
          isLoading={isLoading}
          pageSizeLimit={7}
          isSearch={true}
          //   handleDelete={removeDevices}
          //   handleUpdate={handleUpdate}
          //   handleViewMore={handleViewMore}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
};

export default ValidationList;
