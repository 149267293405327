import React from "react";
import AdminAppShell from "../../../../layouts/AdminAppShell";
import { useParams, useLocation } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import DataTableThree from "../../../../components/DataTableThree";

export const SingleTransactionReport = () => {
  let { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const topUpDate = queryParams.get("top_up_date");
  console.log(topUpDate);

  const columns = [
      { Header: "Pan ID", accessor: "pan_id" },
      { Header: "Top Up Date", accessor: "topup_date" },
      { Header: "Amount", accessor: "amount"},
      { Header: "Type", accessor: "type"},
      { Header: "Status", accessor: "status"},
      { Header: "External Sync Status", accessor: "external_sync_status"},
      { Header: "External Sync TS", accessor: "external_sync_ts"},
      { Header: "Remarks", accessor: "remarks"}
    ];

  return (
    <AdminAppShell>
      <Flex alignItems="center" gap={2} mb={2}>
        <PcCaseIcon size={25} />
        <Box fontSize="xl" fontWeight="bold">
          More Info: {id}
        </Box>
      </Flex>

      <Box py={6}>
          <DataTableThree
            columns={columns}
            fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_topup_recon/recon-detail?top_up_date=${topUpDate}`}
            tokenKey="jwt_access_token"
            downloadFunc={() => {}}
            showActions={false}
            showExports={false}
          />
        </Box>
    </AdminAppShell>
  );
};
