import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Box,
  Text,
  Checkbox,
  Flex,
} from "@chakra-ui/react";

export const ExportModal = ({
  isOpen,
  onClose,
  data,
  exportData,
  selectedExport,
  setSelectedExport,
  exportTitle,
  dateRangeFilter,
  setDateRangeFilter, 
  handleDateRangeFilterReset
}: any) => {
  const handleExport = (format: any) => {
    // exportData({ data, fileName: "exportedData", format });
    // onClose();
    exportData({ data, fileName: "test-example" }, format);
  };

  const handleSelectedClick = (e: any) => {
    setSelectedExport(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {
      onClose()
      handleDateRangeFilterReset("")
    }} size={"xl"}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Export Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box paddingBottom={"10"}>
              <p>Select the format to export the data:</p>

              <Stack display={"flex"} flexDirection={"row"} justifyContent={'center'} gap={"4"}>
                {/* <Button onClick={() => handleExport("pdf")}>PDF</Button> */}
                <Button
                  colorScheme={selectedExport === "0" ? "green" : "blue"}
                  value={0}
                  onClick={(e) => handleSelectedClick(e)}
                >
                  Full {exportTitle} History Data
                </Button>
                <Button
                  colorScheme={selectedExport === "1" ? "green" : "blue"}
                  value={1}
                  onClick={(e) => handleSelectedClick(e)}
                >
                  Filtered {exportTitle} History
                </Button>
              </Stack>
            </Box>

            {selectedExport === "0" && (
              <>
                <Text textAlign={"center"} fontWeight={"black"}>
                  Full {exportTitle} Data
                </Text>
                <Text textAlign={"center"} color={"red"} fontWeight={"bold"}>
                  Please choose a format to export the full {exportTitle} data.
                </Text>
                <Stack display={"flex"}>
                  {/* <Button onClick={() => handleExport("pdf")}>PDF</Button> */}
                  <Flex
                    py={4}
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Text textAlign={'center'} fontWeight={"bold"}>
                      Do you want to include the date range filter when
                      exporting your {exportTitle} data
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox colorScheme="green" value={"1"} onChange={setDateRangeFilter} isChecked={dateRangeFilter === "1"}>
                        Yes
                      </Checkbox>
                      <Checkbox colorScheme="red" value={"0"} onChange={setDateRangeFilter} isChecked={dateRangeFilter === "0"}>
                        No
                      </Checkbox>
                    </Stack>
                  </Flex>

                  <Button onClick={() => handleExport("csv")} isDisabled={!dateRangeFilter}>CSV</Button>
                  <Button onClick={() => handleExport("xlsx")} isDisabled={!dateRangeFilter}>Excel</Button>
                </Stack>
              </>
            )}
            {selectedExport === "1" && (
              <>
                {" "}
                <Text textAlign={"center"} fontWeight={"black"}>
                  Filtered {exportTitle} Data
                </Text>
                <Text textAlign={"center"} color={"red"} fontWeight={"bold"}>
                  Please choose a format to export the filtered {exportTitle}{" "}
                  data.
                </Text>
                <Stack display={"flex"}>
                  {/* <Button onClick={() => handleExport("pdf")}>PDF</Button> */}

                  <Flex
                    py={4}
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Text textAlign={'center'} color={"red"} fontWeight={"bold"}>
                      Do you want to include the date range filter when
                      exporting your {exportTitle} data
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox colorScheme="green" value={"1"} onChange={setDateRangeFilter} isChecked={dateRangeFilter === "1"}>
                        Yes
                      </Checkbox>
                      <Checkbox colorScheme="red" value={"0"} onChange={setDateRangeFilter} isChecked={dateRangeFilter === "0"}>
                        No
                      </Checkbox>
                    </Stack>
                  </Flex>

                  <Button onClick={() => handleExport("csv")} isDisabled={!dateRangeFilter}>CSV</Button>
                  <Button onClick={() => handleExport("xlsx")} isDisabled={!dateRangeFilter}>Excel</Button>
                </Stack>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => {
      onClose()
      handleDateRangeFilterReset("")
    }}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
