import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import DatatableThree from "../../components/DataTableThree";
import DatatableTwo from "../../components/DataTableTwo";
import { useReconStore, useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";

const Test = () => {

  const downloadDataFunc = useReconStore((state) => state.downloadProcessingFeeData)


  const columns = [
    { Header: "Sanral ID", accessor: "sanral_id" },
    { Header: "Accounting Date", accessor: "accounting_date" },
    { Header: "Transaction Concept", accessor: "transaction_concept" },
    { Header: "Processing Fee", accessor: "processing_fee" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "VAT Amount", accessor: "vat_amount" },
    // { Header: "Comment", accessor: "comment" },
  ];

  return (
    <AdminAppShell>
      <Box>
        <DatatableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_invoice_financial_statements/processing-fee`}
          tokenKey="jwt_access_token"
          downloadFunc={downloadDataFunc}
        />
      </Box>
    </AdminAppShell>
  );
};

export default Test;
