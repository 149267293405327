import { Box, Flex } from '@chakra-ui/react';
import { PcCaseIcon } from 'lucide-react';
import React from 'react'
import { useParams } from 'react-router-dom';
import DataTableThree from '../../../../components/DataTableThree';
import AdminAppShell from '../../../../layouts/AdminAppShell';

export const ViewSingleException = () => {

    let { id } = useParams();
    const columns = [
        {Header: "Agg ID", accessor: "agg_id"},
        {Header: "Facs ID", accessor: "facs_id"},
        {Header: "Pan", accessor: "pan"},
        {Header: "Amount", accessor: "amount"},
        {Header: "Remarks", accessor: "remarks"}
    ]
  return (
    <AdminAppShell>
        <Flex alignItems="center" gap={2}>
        <PcCaseIcon size={35} />
        <Box fontSize="3xl" fontWeight="bold">
          Exceptions Detailed Report
        </Box>
      </Flex>


      <Box py={6}>
        <DataTableThree
             columns={columns}
             fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_exceptions/detailed_exceptions?mst_id=${id}`}
             tokenKey="jwt_access_token"
             downloadFunc={() => {}}
             showActions={false}
             showExports={false}
        />
      </Box>
    </AdminAppShell>
  )
}
