const devicesData: any  = [
    {
        id: "7139f80f-3357-41a3-903f-83ed2da6cc61",
        name: "test device",
        make: "device make",
        enabled: false,
        model: "model01",
        type: "tab",
        mac_address: "00:1B:44:11:3A:B9",
        serialno: "00:12:34:56"
    },
    {
        id: "7139f80f-3357-41a3-903f-83ed2da6cc61",
        name: "test device",
        make: "device make",
        enabled: false,
        model: "model01",
        type: "tab",
        mac_address: "00:1B:44:11:3A:B9",
        serialno: "00:12:34:56"
    },
]


export default devicesData