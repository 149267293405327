import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import DataTableThree from "../../../../components/DataTableThree";
import AdminAppShell from "../../../../layouts/AdminAppShell";
import { useParams, useLocation } from "react-router-dom";

export const SingleTransactionStatement = () => {
  let { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refNum = queryParams.get("refNum");
  console.log(refNum);

  const dtlFinancialStatementsColumn = [
    { Header: "Sanral ID", accessor: "sanral_id" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "VAT Amount", accessor: "vat_amount" },
    { Header: "Accounting Date", accessor: "accounting_date"},
    { Header: "Transaction Concept", accessor: "transaction_concept"},
    { Header: "Processing Fee Mode", accessor: "processing_fee_mode"},
    { Header: "TCH Statement Number", accessor: "tch_statement_number"},
    { Header: "TCH Statement Date", accessor: "tch_statement_date"}
  ];

  const dtlInvoiceStatementsColumn = [
    { Header: "Reference Number", accessor: "reference_number" },
    { Header: "Issue Date", accessor: "issue_date"},
    { Header: "Total Amount", accessor: "total_amount"},
    { Header: "VAT Amount", accessor: "vat_amount"},
    { Header: "TCH Statement Number", accessor: "tch_statement_number"},
    { Header: "TCH Statement Date", accessor: "tch_statement_date"}
  ];

  const dtlInvoiceFinancialStatementsColumn = [
    { Header: "Sanral ID", accessor: "sanral_id" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "VAT Amount", accessor: "vat_amount" },
    { Header: "Sanral ID", accessor: "sanral_id"},
    { Header: "Accounting Date", accessor: "accounting_date"},
    { Header: "Transaction Concept", accessor: "transaction_concept"},
    { Header: "Processing Fee Mode", accessor: "processing_fee_mode"},
    { Headers: "Processing Fee", accessor: "processing_fee"},
    { Headers: "Comments", accessor: "comment"},
    { Header: "TCH Statement Number", accessor: "tch_statement_number"},
    { Header: "TCH Statement Date", accessor: "tch_statement_date"}
  ];

  const dtlTransitTransactionStatementsColumn = [
    { Header: "Financial ID", accessor: "financial_id" },
    { Header: "Financial TT Amount", accessor: "fin_tt_amount"},
    { Header: "Invoice TT Amount", accessor: "invoice_tt_amount"},
    { Header: "TCH Statement Number", accessor: "tch_statement_number"},
    { Header: "TCH Statement Date", accessor: "tch_statement_date"},
    { Header: "Transit TXN ID", accessor: "transit_txn_id"},
    { Header: "TCH GUID", accessor: "tch_guid"},
    { Header: "Invoice Ref Number", accessor: "invoice_ref_number"},
    {Header: "Invoice Fin ID", accessor: "inv_fin_id"}
  ];

  return (
    <AdminAppShell>
      <Flex alignItems="center" gap={2} mb={2}>
        <PcCaseIcon size={25} />
        <Box fontSize="xl" fontWeight="bold">
          More Info: {id}
        </Box>
      </Flex>
      <Tabs py={6}>
        <TabList>
          <Tab>Detailed Financial Statements</Tab>
          <Tab>Detailed Invoice Statements</Tab>
          <Tab>Detailed Invoice Financial Statements</Tab>
          <Tab>Detailed Transit Transactions</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box>
              <DataTableThree
                columns={dtlFinancialStatementsColumn}
                fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_financial_statements?tch_statement_number=${refNum}`}
                tokenKey="jwt_access_token"
                downloadFunc={() => {}}
                showActions={false}
                showExports={false}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <DataTableThree
                columns={dtlInvoiceStatementsColumn}
                fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_invoice_statements?tch_statement_number=${refNum}`}
                tokenKey="jwt_access_token"
                downloadFunc={() => {}}
                showActions={false}
                showExports={false}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <DataTableThree
                columns={dtlInvoiceFinancialStatementsColumn}
                fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_invoice_financial_statements?tch_statement_number=${refNum}`}
                tokenKey="jwt_access_token"
                downloadFunc={() => {}}
                showActions={false}
                showExports={false}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <DataTableThree
                columns={dtlTransitTransactionStatementsColumn}
                fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_transit_transaction?tch_statement_number=${refNum}`}
                tokenKey="jwt_access_token"
                downloadFunc={() => {}}
                showActions={false}
                showExports={false}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </AdminAppShell>
  );
};
