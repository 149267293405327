import { Box, Flex, Select, Spacer, useToast } from "@chakra-ui/react";
// import _ from "lodash";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
// import Datatable from "../../components/DataTable";
import DatatableTwo from "../../components/DataTableTwo";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
// import { formatDateToISOString } from "../../utils";
// import { exportData } from "../../utils/reportingDownloads";

const TransactionHistory = () => {
  const toast = useToast()
  const fetchAllTransactions = useStore((state) => state.fetchPosTransactions);
  const posTransactions = useStore((state) => state.transactions);
  const fetchTransactionsByDate = useStore((state) => state.fetchTransactionsByDate)
  const isLoading = useStore((state) => state.isLoading)


  useEffect(() => {
    fetchAllTransactions();
  }, [fetchAllTransactions]);


  const fetchTransactionData = (data: any) => {
    const newDates = {
      start_date: data.start_date.toISOString().split("T")[0], 
      end_date: data.end_date.toISOString().split("T")[0]
    }
    
    fetchTransactionsByDate(newDates).then((res: any) => {
      if (res.detail){
        toast({
          title: "Error",
          description: `${res.detail}`,
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Success",
          description: `Date range filter for transactions was applied successfully`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    })
  }

  return (
    <AdminAppShell>
      <Flex direction={"column"} gap={"8"}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} gap={"3"}>
            <PcCaseIcon size={30} />
            <Box fontSize="2xl" fontWeight={"bold"}>
              {" "}
              Viewing Transit transaction
              history
            </Box>
          </Box>

          <Spacer />
        </Flex>
          <Box>

            <DatatableTwo
              columnsToSelect={[
                {
                  Header: "Transaction Date Time",
                  accessorKey: "transaction_datetime",
                  id: 1
                },
                {
                  Header: "Pan ID",
                  accessorKey: "pan_id",
                  id: 2
                },
                {
                  Header: "Location ID",
                  accessorKey: "location_id",
                  id: 3
                },
                {
                  Header: "Total Amount",
                  accessorKey: "total_amount",
                  id: 4
                },
                {
                  Header: "Operator",
                  accessorKey: "pto",
                  id: 5
                },
                {
                  Header: "Vehicle ID",
                  accessorKey: "vehicle_id",
                  id: 6
                },
                {
                  Header: "Sync Status",
                  accessorKey: "external_sync_status",
                  id: 7
                },
                {
                  Header: "Sync Timestamp",
                  accessorKey: "external_sync_ts",
                  id: 8
                },
                {
                  Header: "Remarks",
                  accessorKey: "remarks",
                  id: 9
                },
              ]}
              headerColumns={[
                {
                  Header: "Transaction Date Time",
                  accessorKey: "transaction_datetime",
                  id: 1
                },
                {
                  Header: "Pan ID",
                  accessorKey: "pan_id",
                  id: 2
                },
                {
                  Header: "Location ID",
                  accessorKey: "location_id",
                  id: 3
                },
                {
                  Header: "Total Amount",
                  accessorKey: "total_amount",
                  id: 4
                },
                {
                  Header: "Operator",
                  accessorKey: "pto",
                  id: 5
                },
                {
                  Header: "Vehicle ID",
                  accessorKey: "vehicle_id",
                  id: 6
                },
                {
                  Header: "Sync Status",
                  accessorKey: "external_sync_status",
                  id: 7
                },
                {
                  Header: "Sync Timestamp",
                  accessorKey: "external_sync_ts",
                  id: 8
                },
                {
                  Header: "Remarks",
                  accessorKey: "remarks",
                  id: 9
                },
              ]}
              data={posTransactions}
              dataTableHeading={"Transactions"}
              handleDelete={() => { }}
              handleUpdate={() => { }}
              handleViewMore={() => { }}
              handleDateDataFilter={fetchTransactionData}
              fetchAllTransactions={fetchAllTransactions}
              isSearch={true}
              isActions={false}
              isLoading={isLoading}
              isDownloadActions={true}
              pageSizeLimit={7}
            />
          </Box>
      </Flex>
    </AdminAppShell>
  );
};

export default TransactionHistory;
