import { Box, Flex } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import DataTableThree from "../../../components/DataTableThree";
import { useReconStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

export const TransactionReport = () => {
  const navigate = useNavigate();
  const columns = [
    { Header: "File Name", accessor: "file_name" },
    { Header: "File Gen ts", accessor: "file_gen_ts" },
    { Header: "Total Records", accessor: "total_records" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "Total Charges", accessor: "total_charges" },
    { Header: "Total Nett", accessor: "total_nett" },
    { Header: "File Top Up Date", accessor: "file_topup_date" },
  ];
  const downloadTopUpTransactionReport = useReconStore((state) => state.downloadTopUpTransactionReport)

  const handleMoreClick = (item: any) => {
    console.log(item);
    navigate(
      `/admin/hyphen-transaction/${item.id}?top_up_date=${item.file_topup_date}`
    );
  };

  const handleDownloadClick = (item: any) => {
    downloadTopUpTransactionReport({
        format: "csv",
        top_up_date: item.file_topup_date
    }).then((res: any) => {
        console.log(res)
        // place notification
    }).catch((error: any) => {
        console.log(error)
        // place notification
    })
  }

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Transaction Report
          </Box>
        </Flex>
      </Flex>

      <Box py={4}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_topup_recon`}
          tokenKey="jwt_access_token"
          handleDownloadFunc={handleDownloadClick}
          showActions={true}
          showExports={false}
          handleMore={handleMoreClick}
        />
      </Box>
    </AdminAppShell>
  );
};
