import { Box, Flex } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import DataTableThree from "../../../components/DataTableThree";
import { useReconStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

export const ProcessingFeeInvoice = () => {

  const downloadDataFunc = useReconStore((state) => state.downloadProcessingFeeData)

  const columns = [
    { Header: "TCH Statement Number", accessor: "tch_statement_number"},
    { Header: "Sanral ID", accessor: "sanral_id" },
    { Header: "Accounting Date", accessor: "accounting_date" },
    { Header: "Transaction Concept", accessor: "transaction_concept" },
    { Header: "Processing Fee", accessor: "processing_fee" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "VAT Amount", accessor: "vat_amount" },
    { Header: "TCH Statement Date", accessor: "tch_statement_date" },
  ];

  return (
    <AdminAppShell>
      <Flex alignItems="center" gap={2}>
        <PcCaseIcon size={35} />
        <Box fontSize="3xl" fontWeight="bold">
          Processing Fee Invoices
        </Box>
      </Flex>

      <Box py={6}>
        <DataTableThree 
             columns={columns}
             fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_invoice_financial_statements/processing-fee`}
             tokenKey="jwt_access_token"
             handleDownloadFunc={() => {}}
             downloadFunc={downloadDataFunc}
             showActions={false}
        />
      </Box>
    </AdminAppShell>
  );
};
