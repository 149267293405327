// utils/RoleGuard.tsx

import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../../hooks'; // Import your Zustand store

interface RoleGuardProps {
  children: ReactNode;
  allowedRoles: string[];
}

export const RoleGuard: React.FC<RoleGuardProps> = ({ children, allowedRoles }) => {
  const posUser = useAuthStore((state) => state.user)
  
  // Check if the user object exists and has the role property
  const userRole: string | undefined = posUser?.role?.title;

  // Check if the user role matches any of the allowed roles
  const isAuthorized: boolean = !!userRole && allowedRoles.includes(userRole);
  
  // Render the children if authorized, otherwise redirect
  return isAuthorized ? <>{children}</> : <Navigate to="/unauthorized" />;
};

