import {
    Input as ChakraInput,
    InputGroup,
    InputLeftElement,
    InputProps,
    Icon,
  } from "@chakra-ui/react";
  import { FiSearch } from "react-icons/fi";
  import React from "react";
  
  interface FormInputsProps extends InputProps {
    placeholderText: string;
    backgroundColor?: string;
    textcolor?: string;
    imagemarginleft?: string;
    imagemarginright?: string;
    imagemarginbottom?: string;
    imagemargintop?: string;
  }
  
  const SearchBar: React.FC<FormInputsProps> = ({
    imagemargintop,
    imagemarginbottom,
    textcolor,
    placeholderText,
    backgroundColor,
    imagemarginleft,
    imagemarginright,
    ...rest
  }) => {
    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon
            marginTop={imagemargintop}
            marginBottom={imagemarginbottom}
            marginLeft={imagemarginleft}
            marginRight={imagemarginright}
            as={FiSearch}
          />
        </InputLeftElement>
        <ChakraInput
          {...rest}
          placeholder={placeholderText}
          textStyle="md/Normal"
          fontSize="16px"
          lineHeight="24px"
          textAlign="left"
          color={textcolor}
          backgroundColor={backgroundColor}
        />
      </InputGroup>
    );
  };
  
  export default SearchBar;
  