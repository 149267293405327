import React from "react";
import { Button, Heading } from "@chakra-ui/react";
import { useColorMode } from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import AdminAppShell from "../../layouts/AdminAppShell";

const Home = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <AdminAppShell>
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        <Heading textAlign={'center'}>Welcome to the FairPay <br/> POS Admin Platform</Heading>
        <div>
          <Button onClick={toggleColorMode}>
            {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          </Button>
        </div>
      </main>
    </AdminAppShell>
  );
};

export default Home;
