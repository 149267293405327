import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Input,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

const Tariff = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleTariff = useStore((state) => state.fetchSingleTariff);
  const posTariff = useStore((state) => state.tariff);

  useEffect(() => {
    if (id) {
      fetchSingleTariff({ id: id });
    }
  }, [fetchSingleTariff, id]);

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Tariff - {posTariff ? posTariff?.route_code : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box>
        <Card align={"center"}>
          <CardBody>
            <Flex direction={"column"} gap={"3"} justifyContent={"center"}>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Route Code:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.route_code} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Code:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.code} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Start Location:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.start_loc} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  End Location:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.end_loc} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Amount:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.amount} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Type:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.type} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Created:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.created_ts} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Updated:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.updated_ts} />
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </AdminAppShell>
  );
};

export default Tariff;
