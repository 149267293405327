import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useStore } from "../../hooks";
import Datatable from "../../components/DataTable";
import { useNavigate } from "react-router-dom";

const UserManagement = () => {


  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const toast = useToast();
  const router = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState(null);
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);

  const isLoading = useStore((state) => state.loading);
  const posInternalUsers = useStore((state) => state.internalUsers);
  const fetchAllUsers = useStore((state) => state.fetchAllUsers);
  const removeInternalUser = useStore((state) => state.removeInternalUser);
  const fetchRoles = useStore((state) => state.fetchAllRoles);
  const posRoles = useStore((state) => state.roles);
  const createInternalUser = useStore((state) => state.createInternalUser);

  useEffect(() => {
    fetchAllUsers();
    fetchRoles();
  }, []);

  const handleUpdate = (item: any) => {
    setUser(item);
    if (user) {
      router(`/admin/user-management/${item.id}`);
    }
  };

  const handleViewMore = () => {};

  // Formik validation schema
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    role_id: Yup.string().required("Role is required"),
    is_active: Yup.boolean(),
  });

  // Mock function to handle user submission
  const handleUserSubmit = (values: any) => {
    setLoading(true);

    createInternalUser(values).then((res: any) => {
      if (res) {
        toast({
          title: "User created.",
          description: "New user has been successfully created.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        onClose();

        toast({
          title: "User created.",
          description: "New user has been successfully created.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        onClose();

        fetchAllUsers();
      }
    });
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Users
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setUser(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create User
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
          {overlay}
          <ModalContent>
            <ModalHeader>Create a New User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: "",
                  role_id: "",
                  is_active: true,
                }}
                validationSchema={validationSchema}
                onSubmit={handleUserSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Stack spacing={4}>
                      <Field name="first_name">
                        {({ field, form }: any) => (
                          <FormControl isInvalid={form.errors.first_name && form.touched.first_name}>
                            <FormLabel htmlFor="first_name">First Name</FormLabel>
                            <Input {...field} id="first_name" placeholder="First Name" />
                            <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="last_name">
                        {({ field, form }: any) => (
                          <FormControl isInvalid={form.errors.last_name && form.touched.last_name}>
                            <FormLabel htmlFor="last_name">Last Name</FormLabel>
                            <Input {...field} id="last_name" placeholder="Last Name" />
                            <FormErrorMessage>{form.errors.last_name}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="email">
                        {({ field, form }: any) => (
                          <FormControl isInvalid={form.errors.email && form.touched.email}>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <Input {...field} id="email" placeholder="Email" />
                            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="role_id">
                        {({ field, form }: any) => (
                          <FormControl isInvalid={form.errors.role && form.touched.role}>
                            <FormLabel htmlFor="role">Role</FormLabel>
                            <Select
                            {...field}
                            placeholder="Select option"
                            id="role_id"
                          >
                            {posRoles.length > 0 &&
                              posRoles.map((role: any, idx: number) => (
                                <option key={idx} value={role.id}>
                                  {role.title}
                                </option>
                              ))}
                          </Select>
                            <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="password">
                        {({ field }: any) => (
                          <FormControl>
                            <FormLabel htmlFor="email">Password</FormLabel>
                            <Input {...field} type="password" id="password" placeholder="*****" />
                          </FormControl>
                        )}
                      </Field>

                      <Field name="is_active" type="checkbox">
                        {({ field }: any) => (
                          <FormControl>
                            <Checkbox {...field} id="is_active" colorScheme="green">
                              Is Active
                            </Checkbox>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>

                    <Button colorScheme="green"  isLoading={isSubmitting || loading} type="submit" w={'full'} mt={4}>
                        Save
                      </Button>

                    <ModalFooter></ModalFooter>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>

      <Box py={6}>
        <Datatable
          headerColumns={[
            { Header: "First Name", accessorKey: "first_name" },
            { Header: "Last Name", accessorKey: "last_name" },
            { Header: "Email", accessorKey: "email" },
            { Header: "Is Active", accessorKey: "is_active" },
          ]}
          data={posInternalUsers}
          dataTableHeading={"Users"}
          handleDelete={removeInternalUser}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
      </Box>
    </AdminAppShell>
  );
};

export default UserManagement;
