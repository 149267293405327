import { Box, Flex } from '@chakra-ui/react'
import { PcCaseIcon } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import DataTableThree from '../../../components/DataTableThree'
import { useReconStore } from '../../../hooks'
import AdminAppShell from '../../../layouts/AdminAppShell'

export const ViewExceptions = () => {
    const navigate = useNavigate()
    const downloadExceptionReport = useReconStore((state) => state.downloadExceptionReport)
    const columns = [
        {Header: "File Name", accessor: "file_name"},
        {Header: "Size", accessor: "size"},
        {Header: "Records", accessor: "records"},
        {Header: "Record Type", accessor: "record_type"}
    ]

    const handleMoreClick = (item: any) => {
       navigate(`/admin/exceptions/${item.id}`)
      }

    const handleDownloadClick = (item: any) => {
        downloadExceptionReport({
            mstId: item.id,
            id: item.id,
            fileType: "csv"
        }).then((res: any) => {
            console.log(res)
            // place notification
        }).catch((error: any) => {
            console.log(error)
            // place notification
        })
    }

  return (
    <AdminAppShell>
        <Flex alignItems="center" gap={2}>
        <PcCaseIcon size={35} />
        <Box fontSize="3xl" fontWeight="bold">
          Exceptions Report
        </Box>
      </Flex>


      <Box py={6}>
        <DataTableThree
             columns={columns}
             fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_exceptions`}
             tokenKey="jwt_access_token"
             downloadFunc={() => {}}
             showActions={true}
             showExports={false}
             handleMore={handleMoreClick}
             handleDownloadFunc={handleDownloadClick}
        />
      </Box>
    </AdminAppShell>
  )
}
