import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect } from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import * as Yup from "yup";
import Datatable from "../../components/DataTable";
import { useStore } from "../../hooks";
import TextArea from "antd/es/input/TextArea";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const initialValues = {
  title: "",
  description: "",
  is_active: true,
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  is_active: Yup.boolean().required("Is Active is required"),
});

const Roles = () => {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [role, setRole] = React.useState(null);
  const fetchAllRoles = useStore((state) => state.fetchAllRoles);
  const createNewRole = useStore((state) => state.createNewRole);
  const updateRole = useStore((state) => state.updateRole)
  const resmoveRole = useStore((state) => state.deleteRole)
  const posRoles = useStore((state) => state.roles);
  const isLoading = useStore((state) => state.loading);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const navigate = useNavigate()

  const submitUpdate = (updateData: any) => {
    setLoading(true)

    updateRole(updateData).then((res: any) => {
      if (!_.isEmpty(res)) {
        setLoading(false);
        onClose();
        fetchAllRoles()
        toast({
          title: "Success",
          description: "Operator was updated successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    })
  };

  const handleUpdate = (item: any) => {
    const newPayloadFormat: any = {
      id: item.id,
      title: item.title,
      desription: item.description,
      is_active: item.is_sctive
    }

    setRole(newPayloadFormat);

    if (role) {
      onOpen()
      console.log(role)
    }
  }

  const handleViewMore = (item: any) => {
    navigate(`/admin/roles-management/${item?.id}`)
  }

  const handleSubmit = (roleDetails: any) => {
    setLoading(true);
    createNewRole(roleDetails).then((res: any) => {
      if (res.title) {
        setLoading(false);
        onClose();
        toast({
          title: "Success",
          description: "Operator was added successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        fetchAllRoles()
      } else {
        setLoading(false)
      }
    });
  };

  useEffect(() => {
    fetchAllRoles();
  }, [fetchAllRoles]);

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Roles
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setRole(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create Role
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
          {overlay}
          <ModalContent>
            <ModalHeader>
              {role ? "Update Existing Role" : "Create a New Role"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={role ? role : initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={role ? submitUpdate : handleSubmit}
              >
                <Form>
                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    gap={"1rem"}
                  >
                    <Stack w={"100%"} direction="column" spacing={8}>
                      <Field name="title">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.title && form.touched.title}
                          >
                            <FormLabel>
                              Role Title <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Input {...field} type="text" id="title" />
                            <FormErrorMessage>
                              {form.errors.title &&
                                form.touched.title &&
                                form.errors.title}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="description">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.description &&
                              form.touched.description
                            }
                          >
                            <FormLabel>
                              Role Description{" "}
                              <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <TextArea {...field} type="text" id="description" />
                            <FormErrorMessage>
                              {form.errors.description &&
                                form.touched.description &&
                                form.errors.description}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="is_active">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.is_active && form.touched.is_active
                            }
                          >
                            <FormLabel>
                              Is Role Active{" "}
                              <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Checkbox
                              isChecked={field.value}
                              {...field}
                              colorScheme="teal"
                              size="lg"
                              iconColor="red.500"
                              type="is_active"
                              id="is_active"
                            >
                              Is Active
                            </Checkbox>
                            <FormErrorMessage>
                              {form.errors.is_active &&
                                form.touched.is_active &&
                                form.errors.is_active}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                  </Flex>

                  <Stack direction="row" spacing={4} marginY="1rem">
                    <Button
                      width="100%"
                      colorScheme="teal"
                      variant="solid"
                      type="submit"
                    >
                      {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                    </Button>
                  </Stack>
                </Form>
              </Formik>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              Header: "Title",
              accessorKey: "title",
            },
            {
              Header: "Description",
              accessorKey: "description",
            },
            {
              Header: "Is Active",
              accessorKey: "is_active",
            },
          ]}
          data={posRoles}
          dataTableHeading={"Roles"}
          handleDelete={resmoveRole}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
};

export default Roles;
