import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import { useAuthStore, useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";

const initialValues = {};

const Profile = () => {

  const posUser = useAuthStore((state) => state.user)

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Text fontSize="3xl" fontWeight="bold">
            Profile
          </Text>
        </Flex>
        <Spacer />
      </Flex>
      <Card align="center">
        <CardHeader>
          {/* <Heading size="md">Link a device to an Operator</Heading> */}
        </CardHeader>
        <CardBody w="50vw">
          <Stack direction="column" spacing={6}>     
                <FormControl>
                    <FormLabel w="25%">First Name</FormLabel>
                    <Input readOnly value={posUser?.first_name}/>
                </FormControl>

                <FormControl>
                    <FormLabel w="25%">Last Name</FormLabel>
                    <Input readOnly value={posUser?.last_name}/>
                </FormControl>

                <FormControl>
                    <FormLabel w="25%">Contact Email</FormLabel>
                    <Input readOnly value={posUser?.email}/>
                </FormControl>
          </Stack>
        </CardBody>
      </Card>
    </AdminAppShell>
  );
};

export default Profile;
