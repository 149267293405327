import React, { useEffect, useState } from "react";
import AdminAppShell from "../../../layouts/AdminAppShell";
import { useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Text,
  Avatar,
  VStack,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export const EditUserInfo = () => {
  const { id } = useParams();
  const toast = useToast();
  const fetchSingleInternalUser = useStore(
    (state) => state.fetchSingleInternalUser
  );
  const internalUser = useStore((state) => state.internalUser);
  const fetchRoles = useStore((state) => state.fetchAllRoles);
  const posRoles = useStore((state) => state.roles);
  const updateInternalUser = useStore((state) => state.updateInternalUser);
  const updateInternalUserPassword = useStore((state) => state.updateInternalUserPassword);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePassword = () => setShowPassword(!showPassword);
  const handleToggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    fetchRoles();
    fetchSingleInternalUser({ id: id });
  }, [id, fetchSingleInternalUser, fetchRoles]);

  const userInfoValidationSchema = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    role_id: Yup.string().required("Role is required"),
  });

  const passwordValidationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });



  return (
    <AdminAppShell>
      <Box p={6}>
        {internalUser ? (
          <Flex justifyContent={'space-between'} gap={'6'}>
            {/* Left Section: User Info Form */}
            <Box w={'50%'}>
              <Text fontWeight={"bolder"} fontSize={'xl'} mb={4}>
                Edit User Information
              </Text>
              <VStack align="center">
                <Avatar
                  size="lg"
                  name={internalUser.first_name + " " + internalUser.last_name}
                  src={internalUser.avatarUrl || ""}
                />
                <Text>
                  {internalUser.first_name} {internalUser.last_name}
                </Text>
              </VStack>

              <Formik
                initialValues={{
                  first_name: internalUser.first_name || "",
                  last_name: internalUser.last_name || "",
                  email: internalUser.email || "",
                  is_active: internalUser.is_active || false,
                  role_id: internalUser.role_id || "",
                }}
                validationSchema={userInfoValidationSchema}
                enableReinitialize={true}
                onSubmit={async (values: any, { setSubmitting }: any) => {
                  // Add logic here to send updated data to the backend

                  try {
                    await updateInternalUser({ id, ...values });
                    toast({
                      title: "User updated successfully.",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                    await fetchSingleInternalUser({ id: id });
                  } catch (error: any) {
                    toast({
                      title: "Failed to update user.",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ isSubmitting, errors, touched, setFieldValue, values }: any) => (
                  <Form>
                    <FormControl>
                      <FormLabel htmlFor="first_name">First Name</FormLabel>
                      <Field as={Input} id="first_name" name="first_name" />
                      {errors.first_name && touched.first_name ? (
                        <Text color="red.500">{errors.first_name}</Text>
                      ) : null}
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel htmlFor="last_name">Last Name</FormLabel>
                      <Field as={Input} id="last_name" name="last_name" />
                      {errors.last_name && touched.last_name ? (
                        <Text color="red.500">{errors.last_name}</Text>
                      ) : null}
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Field as={Input} id="email" name="email" type="email" />
                      {errors.email && touched.email ? (
                        <Text color="red.500">{errors.email}</Text>
                      ) : null}
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel htmlFor="role_id">Role</FormLabel>
                      <Field name="role_id">
                        {({ field }: any) => (
                          <Select
                            {...field}
                            placeholder="Select option"
                            id="role_id"
                          >
                            {posRoles.length > 0 &&
                              posRoles.map((role: any, idx: number) => (
                                <option key={idx} value={role.id}>
                                  {role.title}
                                </option>
                              ))}
                          </Select>
                        )}
                      </Field>
                      {errors.role_id && touched.role_id ? (
                        <Text color="red.500">{errors.role_id}</Text>
                      ) : null}
                    </FormControl>

                    <FormControl mt={2}>
                    <Checkbox
                        id="is_active"
                        name="is_active"
                        isChecked={values.is_active} // Bind value to Formik
                        onChange={(e) => setFieldValue("is_active", e.target.checked)}
                      >
                        Is Active
                      </Checkbox>
                      {errors.is_active && touched.is_active ? (
                        <Text color="red.500">{errors.is_active}</Text>
                      ) : null}
                    </FormControl>

                    <Button
                      mt={4}
                      colorScheme="green"
                      type="submit"
                      isLoading={isSubmitting}
                      w={'100%'}
                    >
                      Save Changes
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>

            {/* Right Section: User Details and Password Update */}
            <Box w={'50%'}>
              {/* User Details */}
              <Text fontWeight={"bolder"} mb={4}>
                Other Actions
              </Text>

              {/* Update Password Section */}
              <Text fontWeight={"bolder"} fontSize={'lg'} mb={4}>
                Update Password
              </Text>

              <Formik
                initialValues={{
                  password: "",
                  confirm_password: "",
                }}
                validationSchema={passwordValidationSchema}
                onSubmit={async (values: any, { setSubmitting }: any) => {
                  try {
                    await updateInternalUserPassword({ id, ...values });
                    toast({
                      title: "Password updated successfully.",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                  } catch (error: any) {
                    toast({
                      title: "Failed to update password.",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ isSubmitting, errors, touched }: any) => (
                  <Form>
                    <FormControl mt={4}>
                      <FormLabel htmlFor="password">New Password</FormLabel>
                      <InputGroup>
                      <Field
                        as={Input}
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"} // Toggle between text and password type
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label="Toggle password visibility"
                          icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          onClick={handleTogglePassword}
                          size="sm"
                        />
                      </InputRightElement>
                    </InputGroup>
                      {errors.password && touched.password ? (
                        <Text color="red.500">{errors.password}</Text>
                      ) : null}
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel htmlFor="confirm_password">
                        Confirm New Password
                      </FormLabel>
                      <InputGroup>
                      <Field
                        as={Input}
                        id="confirm_password"
                        name="confirm_password"
                        type={showConfirmPassword ? "text" : "password"} // Toggle between text and password type
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label="Toggle confirm password visibility"
                          icon={showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                          onClick={handleToggleConfirmPassword}
                          size="sm"
                        />
                      </InputRightElement>
                    </InputGroup>
                      {errors.confirm_password && touched.confirm_password ? (
                        <Text color="red.500">{errors.confirm_password}</Text>
                      ) : null}
                    </FormControl>

                    <Button
                      mt={4}
                      colorScheme="green"
                      type="submit"
                      isLoading={isSubmitting}
                      w={`100%`}
                    >
                      Update Password
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Flex>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"50vh"}
          >
            <Spinner />
            <Text fontWeight={"bolder"}>Please wait. Loading....</Text>
          </Box>
        )}
      </Box>
    </AdminAppShell>
  );
};
