import { FlexProps, Flex, IconButton, Stack, List, ListItem, Icon, HStack, Text } from "@chakra-ui/react";
import { GrTemplate } from "react-icons/gr";
import { TiSpannerOutline } from "react-icons/ti";
import { ImWarning } from "react-icons/im";
import { BsChatLeft, BsGraphUpArrow } from "react-icons/bs";
import { CiTempHigh } from "react-icons/ci"
// import { useLiveRostNavContext } from "../../../../context/LiveRostNavProvider";
import { useState } from "react";

type LiveRoastNavProps = FlexProps

const actions = [{ icon: GrTemplate, label: 'OverView' }, { icon: TiSpannerOutline, label: 'Automation' }, { icon: ImWarning, label: 'Alerts' }, { icon: BsChatLeft, label: 'Comments' },{ icon: BsGraphUpArrow, label: 'RoastProfileDetails' },{ icon: CiTempHigh, label: 'Sensors' }]

function LiveRoastNav({ ...rest }: LiveRoastNavProps): React.ReactElement {
    const [isOpen, setIsOpen] = useState(false)

    const onOpen = () => {
        setIsOpen(!isOpen)
    }
    return (<>
        {isOpen && <Flex w="441px" h="100vh" bg="white" transition="all ease 0.3s" borderLeftColor="#E4E7EC" borderLeftWidth={1} >
            <HStack w="100%" h="11.8vh" p="24px" borderColor="#E4E7E" borderBottomWidth={1}>
                <Text>Overview</Text>
            </HStack>
        </Flex>}

        <Flex flexDir="row" h="100vh" minW="82px" bg="white" borderLeftColor="#E4E7EC" borderLeftWidth={1} {...rest}  >
            <Stack justify="center" px="16px" py="32px" flexDir="row">
                <List spacing={2} >
                    {actions.map((action) => (
                        <ListItem key={action.label}>
                            <IconButton aria-label={action.label} onClick={onOpen} p="12px" icon={<Icon as={action.icon} color="black" h="24px" w="24px" />} />
                        </ListItem>
                    ))}
                </List>

            </Stack>
        </Flex>
    </>)
}

export default LiveRoastNav
