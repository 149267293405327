import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/DataTable";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";

const initialValues = {
  location_seq: 0,
  route_direction: "",
  enabled: true,
  route_id: "",
  location_id: "",
};

const validate = (values: any) => {
  const errors: any = {};

  if (!values.location_seq) {
    errors.name = "Location Sequence is required";
  }

  if (!values.route_direction) {
    errors.route_direction = "Route Direction is required";
  }

  if (!values.enabled) {
    errors.enabled = "Enabled is required";
  }

  if (!values.route_id) {
    errors.route_id = "Route ID is required";
  }

  if (!values.location_id) {
    errors.pto_id = "Location ID is required";
  }
};

const Stops = () => {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const fetchStops = useStore((state) => state.fetchStops);
  const createStop = useStore((state) => state.createStop);
  const removeStop = useStore((state) => state.removeStop);
  const updateStop = useStore((state) => state.updateStop);
  const posStops = useStore((state) => state.stops);

  const fetchOperators = useStore((state) => state.fetchOperators);
  const posOperators = useStore((state) => state.operators);
  const fetchRoutes = useStore((state) => state.fetchRoutes)
  const posRoutes = useStore((state) => state.routes)
  const fetchLocations = useStore((state) => state.fetchLocations)
  const posLocations = useStore((state) => state.locations)

  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);
  const [stop, setStop] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate()

  useEffect(() => {
    fetchStops();
    fetchOperators();
    fetchRoutes();
    fetchLocations();
  }, [fetchStops, fetchOperators, fetchRoutes, fetchLocations]);

  const handleSubmit = (stopDetails: any) => {
    setLoading(true);
    stopDetails.enabled = true;
    createStop(stopDetails).then((res: any) => {
      console.log(res)
      if (res.route_direction) {
        setLoading(false);
        onClose();
        fetchStops();
        toast({
          title: "Success",
          description: "Stop was added successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setLoading(false);
      }
    });
  };

  const submitUpdate = (updatedData: any) => {
    setLoading(true);
    console.log("handle update submit", updatedData);

    updateStop(updatedData).then((res: any) => {
      if (!_.isEmpty(res)) {
        setLoading(false);
        onClose();
        fetchStops();
        toast({
          title: "Success",
          description: "Stop was updated successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleUpdate = (item: any) => {
    const newPayloadFormat: any = {
      id: item.id,
      location_seq: item.locaton_seq,
      route_direction: item.route_direction,
      enabled: item.enabled,
      route_id: item.route_id,
      location_id: item.location_id,
    };

    setStop(newPayloadFormat);

    if (stop) {
      onOpen();
      console.log(stop);
    }
  };

  const handleViewMore = (item: any) => {
    navigate(`/admin/stops/${item.id}`)
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Text fontSize="3xl" fontWeight="bold">
            Stops
          </Text>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setStop(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create Stops
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
          {overlay}
          <ModalContent>
            <ModalHeader>
              {stop ? "Update Existing Stop" : "Create a New Stop"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={stop ? stop : initialValues}
                enableReinitialize
                validate={validate}
                onSubmit={stop ? submitUpdate : handleSubmit}
              >
                <Form>
                  <Stack w="100%" direction="column" spacing={8}>
                    <Field name="pto_id">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.pto_id && form.touched.pto_id}
                        >
                          <FormLabel>Operator</FormLabel>
                          <Select
                            {...field}
                            placeholder="Select an operator"
                            type="pto_id"
                            id="pto_id"
                          >
                            {posOperators &&
                              posOperators.map((item: any, index: number) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.pto_id &&
                              form.touched.pto_id &&
                              form.errors.pto_id}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="route_id">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.pto_id && form.touched.pto_id}
                        >
                          <FormLabel>Route</FormLabel>
                          <Select
                            {...field}
                            placeholder="Select a route"
                            type="route_id"
                            id="route_id"
                          >
                            {posRoutes &&
                              posRoutes.map((item: any, index: number) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.route_id &&
                              form.touched.route_id &&
                              form.errors.route_id}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="location_id">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.location_id && form.touched.location_id}
                        >
                          <FormLabel>Location</FormLabel>
                          <Select
                            {...field}
                            placeholder="Select a location"
                            type="location_id"
                            id="location_id"
                          >
                            {posLocations &&
                              posLocations.map((item: any, index: number) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.location_id &&
                              form.touched.location_id &&
                              form.errors.location_id}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="route_direction">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.route_direction &&
                            form.touched.route_direction
                          }
                        >
                          <FormLabel>Route Direction</FormLabel>
                          <Input
                            {...field}
                            type="route_direction"
                            id="route_direction"
                          />
                          <FormErrorMessage>
                            {form.errors.route_direction &&
                              form.touched.route_direction &&
                              form.errors.route_direction}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="enabled">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.enabled && form.touched.enabled
                          }
                        >
                          <FormLabel>Enabled</FormLabel>
                          <Checkbox
                            {...field}
                            colorScheme="teal"
                            size="lg"
                            iconColor="red.500"
                            type="enabled"
                            id="enabled"
                          >
                            Enabled
                          </Checkbox>
                          <FormErrorMessage>
                            {form.errors.enabled &&
                              form.touched.enabled &&
                              form.errors.enabled}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  <Stack direction="row" spacing={4} marginY="1rem">
                    <Button
                      width="100%"
                      colorScheme="teal"
                      variant="solid"
                      type="submit"
                    >
                      {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                    </Button>
                  </Stack>
                </Form>
              </Formik>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              Header: "Route Direction",
              accessorKey: "route_direction",
            },
            {
              Header: "Location Sequence",
              accessorKey: "location_seq",
            },
            {
              Header: "Enabled",
              accessorKey: "enabled",
            },
          ]}
          data={posStops}
          dataTableHeading={"Stops"}
          handleDelete={removeStop}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
};

export default Stops;
