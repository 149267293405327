import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import AdminAppShell from '../../layouts/AdminAppShell'

const Unauthorized = () => {
  return (
    <AdminAppShell>
        <Box boxShadow={'lg'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} p={'4'} h={'80vh'}>
            <Box fontSize={'9xl'}>Oops!</Box>
            <Text fontSize={'2xl'} color={'red.400'} fontWeight={'black'}>403 Permission Denied</Text>
            <Text fontWeight={'black'}>Sorry, you do not have access to this page please contact your administrator</Text>
            <Text color={'red.400'} fontWeight={'black'}>Please contact Admin for support!</Text>
        </Box>
    </AdminAppShell>
  )
}

export default Unauthorized