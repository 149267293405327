import moment from "moment";

export const formatDateToISOString = (date: any, from: string) => {
  if (from === "top-up") {
    const now = moment().format(date);

    // Format the date to the required format
    // const specifiedDate = now.format("YYYY-MM-DD HH:mm:ss");

    const specifiedDate = moment(now).toISOString().split("T")[0];
    console.log(specifiedDate)
    return specifiedDate;
  }

  if (from === "transaction") {
    const now = moment().format(date);

    const specifiedDate = moment(now).toISOString().split("T")[0];
    console.log(specifiedDate)
    return specifiedDate;
  }
};
