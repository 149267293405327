import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const EditTransactionModal = ({ isOpen, onClose, initialValues, onSubmit }: any) => {
  const validationSchema = Yup.object({
    pan_id: Yup.number().required("PAN ID is required"),
    sequence: Yup.number().required("Sequence is required"),
    location_id: Yup.string().nullable(), // Optional field
    transaction_datetime: Yup.date().nullable(),
    identifier_number: Yup.number().required("Identifier Number is required"),
    nominal_amount: Yup.number().nullable(),
    total_amount: Yup.number().nullable(),
    rule_id: Yup.string().nullable(),
    amount: Yup.number().nullable(),
    route_name: Yup.string().nullable(),
    route_direction: Yup.string().nullable(),
    trip_status: Yup.string().nullable(),
    transport_type: Yup.string().nullable(),
    external_sync_status: Yup.string().nullable(),
    external_sync_ts: Yup.date().nullable(),
    remarks: Yup.string().nullable(),
    guid: Yup.string().nullable(),
    txn_guid: Yup.string().nullable(),
    txn_sequence_number: Yup.number().required("Transaction Sequence Number is required"),
    trip_id: Yup.number().required("Trip ID is required"),
    pto: Yup.string().required("PTO is required"),
    vehicle_id: Yup.string().required("Vehicle ID is required"),
    device_serial_number: Yup.string().required("Device Serial Number is required"),
    txn_status: Yup.string().nullable(), // Optional field
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl"> {/* Large size modal */}
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Transaction</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(values);
            onClose();
          }}
        >
          {({ errors, touched }: any) => (
            <Form>
              <ModalBody pb={6}>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}> {/* Two columns layout */}
                  <GridItem>
                    <FormControl isInvalid={errors.pan_id && touched.pan_id}>
                      <FormLabel>PAN ID</FormLabel>
                      <Field as={Input} name="pan_id" />
                      <FormErrorMessage>{errors.pan_id}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.sequence && touched.sequence}>
                      <FormLabel>Sequence</FormLabel>
                      <Field as={Input} name="sequence" />
                      <FormErrorMessage>{errors.sequence}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.location_id && touched.location_id}>
                      <FormLabel>Location ID</FormLabel>
                      <Field as={Input} name="location_id" />
                      <FormErrorMessage>{errors.location_id}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.transaction_datetime && touched.transaction_datetime}>
                      <FormLabel>Transaction DateTime</FormLabel>
                      <Field as={Input} type="datetime-local" name="transaction_datetime" />
                      <FormErrorMessage>{errors.transaction_datetime}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.identifier_number && touched.identifier_number}>
                      <FormLabel>Identifier Number</FormLabel>
                      <Field as={Input} name="identifier_number" />
                      <FormErrorMessage>{errors.identifier_number}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.nominal_amount && touched.nominal_amount}>
                      <FormLabel>Nominal Amount</FormLabel>
                      <Field as={Input} name="nominal_amount" />
                      <FormErrorMessage>{errors.nominal_amount}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.total_amount && touched.total_amount}>
                      <FormLabel>Total Amount</FormLabel>
                      <Field as={Input} name="total_amount" />
                      <FormErrorMessage>{errors.total_amount}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.rule_id && touched.rule_id}>
                      <FormLabel>Rule ID</FormLabel>
                      <Field as={Input} name="rule_id" />
                      <FormErrorMessage>{errors.rule_id}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.amount && touched.amount}>
                      <FormLabel>Amount</FormLabel>
                      <Field as={Input} name="amount" />
                      <FormErrorMessage>{errors.amount}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.route_name && touched.route_name}>
                      <FormLabel>Route Name</FormLabel>
                      <Field as={Input} name="route_name" />
                      <FormErrorMessage>{errors.route_name}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.route_direction && touched.route_direction}>
                      <FormLabel>Route Direction</FormLabel>
                      <Field as={Input} name="route_direction" />
                      <FormErrorMessage>{errors.route_direction}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.trip_status && touched.trip_status}>
                      <FormLabel>Trip Status</FormLabel>
                      <Field as={Input} name="trip_status" />
                      <FormErrorMessage>{errors.trip_status}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.transport_type && touched.transport_type}>
                      <FormLabel>Transport Type</FormLabel>
                      <Field as={Input} name="transport_type" />
                      <FormErrorMessage>{errors.transport_type}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.external_sync_status && touched.external_sync_status} isDisabled={true}>
                      <FormLabel>External Sync Status</FormLabel>
                      <Field as={Input} name="external_sync_status"/>
                      <FormErrorMessage>{errors.external_sync_status}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.external_sync_ts && touched.external_sync_ts}>
                      <FormLabel>External Sync Timestamp</FormLabel>
                      <Field as={Input} type="datetime-local" name="external_sync_ts" />
                      <FormErrorMessage>{errors.external_sync_ts}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.remarks && touched.remarks}>
                      <FormLabel>Remarks</FormLabel>
                      <Field as={Input} name="remarks" />
                      <FormErrorMessage>{errors.remarks}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.guid && touched.guid}>
                      <FormLabel>GUID</FormLabel>
                      <Field as={Input} name="guid" />
                      <FormErrorMessage>{errors.guid}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.txn_guid && touched.txn_guid}>
                      <FormLabel>Transaction GUID</FormLabel>
                      <Field as={Input} name="txn_guid" />
                      <FormErrorMessage>{errors.txn_guid}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.txn_sequence_number && touched.txn_sequence_number}>
                      <FormLabel>Transaction Sequence Number</FormLabel>
                      <Field as={Input} name="txn_sequence_number" />
                      <FormErrorMessage>{errors.txn_sequence_number}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.trip_id && touched.trip_id}>
                      <FormLabel>Trip ID</FormLabel>
                      <Field as={Input} name="trip_id" />
                      <FormErrorMessage>{errors.trip_id}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.pto && touched.pto}>
                      <FormLabel>PTO</FormLabel>
                      <Field as={Input} name="pto" />
                      <FormErrorMessage>{errors.pto}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.vehicle_id && touched.vehicle_id}>
                      <FormLabel>Vehicle ID</FormLabel>
                      <Field as={Input} name="vehicle_id" />
                      <FormErrorMessage>{errors.vehicle_id}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.device_serial_number && touched.device_serial_number}>
                      <FormLabel>Device Serial Number</FormLabel>
                      <Field as={Input} name="device_serial_number" />
                      <FormErrorMessage>{errors.device_serial_number}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.txn_status && touched.txn_status}>
                      <FormLabel>Transaction Status</FormLabel>
                      <Field as={Input} name="txn_status" />
                      <FormErrorMessage>{errors.txn_status}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </Grid>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditTransactionModal;
