import { Button, Flex, Spacer, Text } from '@chakra-ui/react'
import { CarTaxiFrontIcon } from 'lucide-react'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '../../../hooks'
import AdminAppShell from '../../../layouts/AdminAppShell'

const Stop = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const fetchSingleStop = useStore((state) => state.fetchSingleStop);
    const posStop = useStore((state) => state.stop);
  
    useEffect(() => {
      if (id) {
        fetchSingleStop({ id: id });
      }
    }, [fetchSingleStop, id]);

  return (
    <AdminAppShell>
        <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Text fontSize="3xl" fontWeight="bold">
            Stop - {posStop ? posStop?.route_direction : ""}
          </Text>
        </Flex>
        <Spacer />
        <Button colorScheme="blue" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>
    </AdminAppShell>
  )
}

export default Stop