import { Box, Flex, HStack, Text, chakra, Tag, IconButton, Button } from '@chakra-ui/react'
// import { useHeaderStore } from '../../../../stores/useTitleHeaderStore'

type ModeColors = {
    [key: string]: {
      bg: string;
    };
  };

function LiveRoastHeader() {

    function getColorForMode(mode: string): string {
        const modes: ModeColors = {
          Preheat: {
            bg: '#DB8D07',
          },
          Cooldown: {
            bg: '#398DCD',
          },
          Roasting: {
            bg: '#DB303C',
          },
          'BBP Auto': {
            bg: '#6941C6',
          },
        };
      
        if (mode in modes) {
          return modes[mode].bg;
        } else {
          return 'Mode not found';
        }
      }

    // function renderDot({ props }: { props: any }) {
    //     return (
    //         <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width={48}
    //             height={48}
    //             fill="none"
    //             {...props}
    //         >
    //             <g clipPath="url(#a)">
    //                 <rect width={48} height={48} fill="#fff" rx={8} />
    //                 <rect width={48} height={48} fill="url(#b)" rx={8} />
    //                 <path
    //                     fill="#98A2B3"
    //                     fillRule="evenodd"
    //                     d="M24 3.058C12.434 3.058 3.058 12.434 3.058 24S12.434 44.942 24 44.942 44.942 35.566 44.942 24 35.566 3.058 24 3.058ZM2.942 24C2.942 12.37 12.37 2.942 24 2.942c11.63 0 21.058 9.428 21.058 21.058 0 11.63-9.428 21.058-21.058 21.058C12.37 45.058 2.942 35.63 2.942 24Z"
    //                     clipRule="evenodd"
    //                 />
    //                 <path
    //                     fill="#98A2B3"
    //                     fillRule="evenodd"
    //                     d="M24 18.117a5.883 5.883 0 1 0 0 11.767 5.883 5.883 0 0 0 0-11.767ZM18 24a6 6 0 1 1 12 0 6 6 0 0 1-12 0Z"
    //                     clipRule="evenodd"
    //                 />
    //                 <path
    //                     fill="#98A2B3"
    //                     fillRule="evenodd"
    //                     d="M24 19.98a4.02 4.02 0 1 0 0 8.04 4.02 4.02 0 0 0 0-8.04ZM19.864 24a4.136 4.136 0 1 1 8.272 0 4.136 4.136 0 0 1-8.272 0Z"
    //                     clipRule="evenodd"
    //                 />
    //                 <path fill="#98A2B3" d="M23.942 0h.116v48h-.116V0Z" />
    //                 <path fill="#98A2B3" d="M48 23.941v.117H0v-.117h48Z" />
    //                 <path
    //                     fill="#98A2B3"
    //                     d="M39.903 0h.116v48h-.116V0ZM15.961 0h.117v48h-.117V0ZM31.922 0h.117v48h-.117V0ZM7.98 0h.117v48H7.98V0Z"
    //                 />
    //                 <path
    //                     fill="#98A2B3"
    //                     d="M48 39.902v.117H0v-.117h48ZM48 15.96v.117H0v-.116h48ZM48 31.922v.116H0v-.116h48ZM48 7.98v.117H0V7.98h48Z"
    //                 />
    //                 <g filter="url(#c)">
    //                     <circle cx={24} cy={24} r={12} fill={getColorForMode(props)} />
    //                 </g>
    //                 <g filter="url(#d)">
    //                     <path
    //                         fill="#fff"
    //                         fillOpacity={0.2}
    //                         d="M0 24h48v11.2c0 4.48 0 6.72-.872 8.432a8 8 0 0 1-3.496 3.496C41.92 48 39.68 48 35.2 48H12.8c-4.48 0-6.72 0-8.432-.872a8 8 0 0 1-3.496-3.496C0 41.92 0 39.68 0 35.2V24Z"
    //                     />
    //                 </g>
    //             </g>
    //             <defs>
    //                 <filter
    //                     id="c"
    //                     width={30}
    //                     height={30}
    //                     x={9}
    //                     y={10}
    //                     colorInterpolationFilters="sRGB"
    //                     filterUnits="userSpaceOnUse"
    //                 >
    //                     <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //                     <feColorMatrix
    //                         in="SourceAlpha"
    //                         result="hardAlpha"
    //                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //                     />
    //                     <feOffset dy={1} />
    //                     <feGaussianBlur stdDeviation={1} />
    //                     <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
    //                     <feBlend
    //                         in2="BackgroundImageFix"
    //                         result="effect1_dropShadow_11724_77910"
    //                     />
    //                     <feColorMatrix
    //                         in="SourceAlpha"
    //                         result="hardAlpha"
    //                         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //                     />
    //                     <feOffset dy={1} />
    //                     <feGaussianBlur stdDeviation={1.5} />
    //                     <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
    //                     <feBlend
    //                         in2="effect1_dropShadow_11724_77910"
    //                         result="effect2_dropShadow_11724_77910"
    //                     />
    //                     <feBlend
    //                         in="SourceGraphic"
    //                         in2="effect2_dropShadow_11724_77910"
    //                         result="shape"
    //                     />
    //                 </filter>
    //                 <filter
    //                     id="d"
    //                     width={58}
    //                     height={34}
    //                     x={-5}
    //                     y={19}
    //                     colorInterpolationFilters="sRGB"
    //                     filterUnits="userSpaceOnUse"
    //                 >
    //                     <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //                     <feGaussianBlur in="BackgroundImageFix" stdDeviation={2.5} />
    //                     <feComposite
    //                         in2="SourceAlpha"
    //                         operator="in"
    //                         result="effect1_backgroundBlur_11724_77910"
    //                     />
    //                     <feBlend
    //                         in="SourceGraphic"
    //                         in2="effect1_backgroundBlur_11724_77910"
    //                         result="shape"
    //                     />
    //                 </filter>
    //                 <linearGradient
    //                     id="b"
    //                     x1={24}
    //                     x2={24}
    //                     y1={0}
    //                     y2={48}
    //                     gradientUnits="userSpaceOnUse"
    //                 >
    //                     <stop stopColor="#fff" />
    //                     <stop offset={1} stopColor="#D0D5DD" />
    //                 </linearGradient>
    //                 <clipPath id="a">
    //                     <rect width={48} height={48} fill="#fff" rx={8} />
    //                 </clipPath>
    //             </defs>
    //         </svg>
    //     )
    // }

    // const { pageTitle, mode } = useHeaderStore()
    return (
        <Flex as="header" w="100vw" maxW="100%" minH="79px" borderBottomColor="#E4E7EC" borderBottomWidth={1} px="24px" py="12px" align="center" justify="space-between" overflow="hidden">
            <Flex align="center" gap="16px">
                <Box>
                    heey
                </Box>
                <Flex flexDir="column" >
                    <Flex flexDir="column">
                        <Text fontSize="30px">Page Title</Text>
                        <Text><chakra.span color={`red`} mr="8px">heey</chakra.span> Mode</Text>
                        <HStack spacing="23px">
                            <Text>Roast Session</Text>
                            <Tag borderRadius='full'>No Batches</Tag>
                            <Tag borderRadius='full'>00:03:30</Tag>
                        </HStack>
                    </Flex>
                </Flex>
            </Flex>
            <Flex gap="16px">
                <IconButton aria-label='drop-down' />
                <Button colorScheme='blue'>Stop Roast</Button>
            </Flex>
        </Flex>
    )
}

export default LiveRoastHeader
