import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import _ from "lodash";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import Datatable from "../../components/DataTable";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Formik, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const initialValues = {
  name: "",
  national_id: "",
  user_type: "",
  contact_number: "",
  contact_email: "",
  address: "",
  gender: "",
  photo_file: "",
  id_doc_file: "",
  user_code: "",
  user_password: "",
  enabled: true,
  pto_id: "",
};

// const validate = (values: any) => {
//   const errors: any = {};

//   if (!values.name) {
//     errors.name = "Name is required";
//   }

//   if (!values.national_id) {
//     errors.national_id = "National ID is required";
//   } else if (values.national_id)

//   if (!values.contact_number) {
//     errors.contact_number = "Contact Number is required";
//   }

//   if (!values.contact_email) {
//     errors.contact_email = "Contact Email is required";
//   }

//   if (!values.address) {
//     errors.address = "Address is required";
//   }

//   if (!values.gender) {
//     errors.gender = "Gender is required";
//   }

//   if (!values.photo_file) {
//     errors.photo_file = "Photo File is required";
//   }

//   if (!values.id_doc_file) {
//     errors.id_doc_file = "ID Doc File is required";
//   }

//   if (!values.user_code) {
//     errors.user_code = "User Code is required";
//   }

//   // if (!values.user_password) {
//   //   errors.user_password = "User password is required";
//   // } else if (values.user_password && (values.user_password.length < 5 || values.user_password.length > 5)) {
//   //   errors.user_password = "User password must be exactly 5 characters long";
//   // }

//   if (!values.user_password) {
//     errors.user_password = "User password is required";
//    }

//    if (values.user_password && (values.user_password.length !== 5)) {
//     errors.user_password = "User password must be exactly 5 characters long";
//    }

//   if (!values.pto_id) {
//     errors.pto_id = "Operator is required";
//   }

//   if (!values.enabled) {
//     errors.enabled = "Enabled is required";
//   }

//   return errors;
// };
// const userTypes: any = [
//   {
//     name: "Driver",
//     value: "driver",
//   },
// ];

const genders: any = [
  {
    name: "Male",
    value: "MALE",
  },
  {
    name: "Female",
    value: "FEMALE",
  },
  {
    name: "Other",
    value: "OTHER",
  },
];

const Drivers = () => {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const fetchDrivers = useStore((state) => state.fetchDrivers);
  const createDriver = useStore((state) => state.createDriver);
  const removeDriver = useStore((state) => state.removeDriver);
  const updateDriver = useStore((state) => state.updateDriver);
  const posDrivers = useStore((state) => state.drivers);
  const isLoading = useStore((state) => state.loading);

  const fetchOperators = useStore((state) => state.fetchOperators);
  const posOperators = useStore((state) => state.operators);

  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);
  const [driver, setDriver] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    national_id: Yup.string()
      .required("National ID is required")
      .matches(/^\d{13}$/, "National ID must consist of 13 digits")
      .min(13, "National ID must be at least 13 characters long")
      .max(13, "National ID must be 13 characters long"),
    contact_number: Yup.string().required("Contact Number is required"),
    // contact_email: Yup.string().required('Contact Email is required'),
    // address: Yup.string().required("Address is required"),
    // gender: Yup.string().required('Gender is required'),
    // photo_file: Yup.string().required('Photo File is required'),
    // id_doc_file: Yup.string().required('ID Doc File is required'),
    user_code: Yup.string().required("User Code is required"),
    user_password: driver
      ? Yup.string()
          .min(5, "User password must be at least 5 characters long")
          .max(5, "User password must be at least 5 characters long")
      : Yup.string()
          .required("User password is required")
          .min(5, "User password must be at least 5 characters long")
          .max(5, "User password must be at least 5 characters long"),
    pto_id: Yup.string().required("Operator is required"),
    enabled: Yup.bool().required("Enabled is required"),
  });

  useEffect(() => {
    fetchDrivers();
    fetchOperators();
  }, [fetchDrivers, fetchOperators]);

  const handleSubmit = (driverDetails: any) => {
    setLoading(true);

    if (!driverDetails.enabled) {
      driverDetails.enabled = false;
    }

    createDriver(driverDetails).then((res: any) => {
      if (res.name) {
        setLoading(false);
        onClose();
        fetchDrivers();
        toast({
          title: "Success",
          description: "Driver was added successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setLoading(false);
      }
    });
  };

  const submitUpdate = (updatedData: any) => {
    setLoading(true);
    console.log("handle update submit", updatedData);

    if (!updatedData.enabled) {
      updatedData.enabled = false;
    }

    updateDriver(updatedData).then((res: any) => {
      if (!_.isEmpty(res)) {
        setLoading(false);
        onClose();
        fetchDrivers();
        toast({
          title: "Success",
          description: "Driver was updated successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleUpdate = (item: any) => {
    const newPayloadFormat: any = {
      id: item.id,
      name: item.name,
      national_id: item.national_id,
      user_type: item.user_type,
      contact_number: item.contact_number,
      contact_email: item.contact_email,
      address: item.address,
      gender: item.gender,
      photo_file: item.photo_file,
      id_doc_file: item.id_doc_file,
      user_code: item.user_code,
      user_password: item.user_password,
      enabled: item.enabled,
      pto_id: item.pto_id,
    };

    setDriver(newPayloadFormat);

    if (driver) {
      onOpen();
      console.log(driver);
    }
  };

  const handleViewMore = (item: any) => {
    navigate(`/admin/drivers/${item.id}`);
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Drivers
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setDriver(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create Driver
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
          {overlay}
          <ModalContent>
            <ModalHeader>
              {driver ? "Update Existing Driver" : "Create a New Driver"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={driver ? driver : initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                validateOnChange={true}
                onSubmit={driver ? submitUpdate : handleSubmit}
              >
                <Form>
                  <Flex direction="column">
                    <Flex
                      direction="row"
                      justifyContent="space-between"
                      gap="1rem"
                    >
                      <Stack w="100%" direction="column" spacing={8}>
                        <Field name="pto_id">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.pto_id && form.touched.pto_id
                              }
                            >
                              <FormLabel>
                                Operator <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                {...field}
                                placeholder="Select an operator"
                                type="pto_id"
                                id="pto_id"
                              >
                                {posOperators &&
                                  posOperators.map(
                                    (item: any, index: number) => (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.pto_id &&
                                  form.touched.pto_id &&
                                  form.errors.pto_id}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="name">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={form.errors.name && form.touched.name}
                            >
                              <FormLabel>
                                Name <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input {...field} type="name" id="name" />
                              <FormErrorMessage>
                                {form.errors.name &&
                                  form.touched.name &&
                                  form.errors.name}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="national_id">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.national_id &&
                                form.touched.national_id
                              }
                            >
                              <FormLabel>
                                National ID{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                {...field}
                                type="national_id"
                                id="national_id"
                              />
                              <FormErrorMessage>
                                {form.errors.national_id &&
                                  form.touched.national_id &&
                                  form.errors.national_id}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="contact_number">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.contact_number &&
                                form.touched.contact_number
                              }
                            >
                              <FormLabel>
                                Contact Number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                {...field}
                                type="contact_number"
                                id="contact_number"
                              />
                              <FormErrorMessage>
                                {form.errors.contact_number &&
                                  form.touched.contact_number &&
                                  form.errors.contact_number}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="contact_email">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.contact_email &&
                                form.touched.contact_email
                              }
                            >
                              <FormLabel>Contact Email</FormLabel>
                              <Input
                                {...field}
                                type="contact_email"
                                id="contact_email"
                              />
                              <FormErrorMessage>
                                {form.errors.contact_email &&
                                  form.touched.contact_email &&
                                  form.errors.contact_email}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>

                      <Stack w="100%" direction="column" spacing={8}>
                        <Field name="address">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.address && form.touched.address
                              }
                            >
                              <FormLabel>Address</FormLabel>
                              <Input {...field} type="address" id="address" />
                              <FormErrorMessage>
                                {form.errors.address &&
                                  form.touched.address &&
                                  form.errors.address}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="gender">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.gender && form.touched.gender
                              }
                            >
                              <FormLabel>Gender</FormLabel>
                              <Select
                                {...field}
                                placeholder="Select a user type"
                                type="gender"
                                id="gender"
                              >
                                {genders &&
                                  genders.map((item: any, index: number) => (
                                    <option key={index} value={item.value}>
                                      {item.name}
                                    </option>
                                  ))}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.gender &&
                                  form.touched.gender &&
                                  form.errors.gender}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="photo_file">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.photo_file &&
                                form.touched.photo_file
                              }
                            >
                              <FormLabel>Photo File</FormLabel>
                              <Input
                                {...field}
                                type="photo_file"
                                id="photo_file"
                              />
                              <FormErrorMessage>
                                {form.errors.photo_file &&
                                  form.touched.photo_file &&
                                  form.errors.photo_file}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="id_doc_file">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.id_doc_file &&
                                form.touched.id_doc_file
                              }
                            >
                              <FormLabel>ID Doc File </FormLabel>
                              <Input
                                {...field}
                                type="id_doc_file"
                                id="id_doc_file"
                              />
                              <FormErrorMessage>
                                {form.errors.id_doc_file &&
                                  form.touched.id_doc_file &&
                                  form.errors.id_doc_file}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="enabled">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.enabled && form.touched.enabled
                              }
                            >
                              <FormLabel>
                                Enabled <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Checkbox
                                isChecked={field.value}
                                {...field}
                                colorScheme="teal"
                                size="lg"
                                iconColor="red.500"
                                type="enabled"
                                id="enabled"
                              >
                                Enabled
                              </Checkbox>
                              <FormErrorMessage>
                                {form.errors.enabled &&
                                  form.touched.enabled &&
                                  form.errors.enabled}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                    </Flex>

                    <Box position="relative">
                      <Flex
                        direction={"column"}
                        justifyContent={"space-between"}
                        gap={"1rem"}
                        marginTop={"6"}
                      >
                        <Divider />

                        <Text fontWeight={"bolder"} color="red">
                          SET DRIVER CREDENTIALS
                        </Text>

                        <Flex direction={"row"} gap={"1rem"}>
                          <Field name="user_code">
                            {({ field, form }: any) => (
                              <FormControl
                                isInvalid={
                                  form.errors.user_code &&
                                  form.touched.user_code
                                }
                              >
                                <FormLabel>
                                  User Code (mobile number){" "}
                                  <span style={{ color: "red" }}>*</span>
                                </FormLabel>
                                <Input
                                  {...field}
                                  type="user_code"
                                  id="user_code"
                                />
                                <FormErrorMessage>
                                  {form.errors.user_code &&
                                    form.touched.user_code &&
                                    form.errors.user_code}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          {driver ? (
                            <></>
                          ) : (
                            <Field name="user_password">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.user_password &&
                                    form.touched.user_password
                                  }
                                >
                                  <FormLabel>
                                    Login Pin{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Input
                                    {...field}
                                    type="user_password"
                                    id="user_password"
                                  />
                                  <FormErrorMessage>
                                    {form.errors.user_password &&
                                      form.touched.user_password &&
                                      form.errors.user_password}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          )}
                        </Flex>
                      </Flex>
                    </Box>
                  </Flex>

                  <Stack direction="row" spacing={4} marginY="1rem">
                    <Button
                      width="100%"
                      colorScheme="teal"
                      variant="solid"
                      type="submit"
                    >
                      {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                    </Button>
                  </Stack>
                </Form>
              </Formik>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              Header: "Name",
              accessorKey: "name",
            },
            {
              Header: "National ID",
              accessorKey: "national_id",
            },
            {
              Header: "Contact Number",
              accessorKey: "contact_number",
            },
            {
              Header: "Contact Email",
              accessorKey: "contact_email",
            },
            // {
            //   Header: "Address",
            //   accessorKey: "address",
            // },
            {
              Header: "Gender",
              accessorKey: "gender",
            },
            // {
            //   Header: "User Code",
            //   accessorKey: "user_code",
            // },
            {
              Header: "Enabled",
              accessorKey: "enabled",
            },
          ]}
          data={posDrivers}
          dataTableHeading={"Drivers"}
          handleDelete={removeDriver}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
};

export default Drivers;
