import { Box, Flex } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect } from "react";
import Datatable from "../../../components/DataTable";
import DataTableThree from "../../../components/DataTableThree";
import { useReconStore, useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

export const TransactionStatus = () => {
  
  const downloadTransactionStatement = useReconStore((state) => state.downloadTransactionStatement)
  const columns = [
    { Header: "TXN GUID", accessor: "txn_guid" },
    { Header: "TCH GUID", accessor: "tch_guid"},
    { Header: "Invoicing ID", accessor: "invoicing_id"},
    { Header: "Status", accessor: "status"},
    { Header: "MST Serial Number", accessor: "mst_serial_number"}
  ]

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Transaction Statuses
          </Box>
        </Flex>
      </Flex>

      <Box py={4}>
      <DataTableThree
             columns={columns}
             fetchUrl={`${process.env.REACT_APP_BASE_URL}/transaction_statuses`}
             tokenKey="jwt_access_token"
             downloadFunc={downloadTransactionStatement}
             handleDownloadFunc={() => {}}
             showActions={false}
        />
        </Box>
    </AdminAppShell>
  );
};
