import React from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Box, Flex } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import DataTableThree from "../../components/DataTableThree";
import { useReconStore } from "../../hooks";

export const TransactionMao = () => {

    const downloadTransactionMapReport = useReconStore((state) => state.downloadTransactionMapReport)

  const columns = [
    { Header: "GUID", accessor: "guid" },
    { Header: "TXN GUID", accessor: "txn_guid" },
    { Header: "Status", accessor: "status" },
    { Header: "Status Meaning", accessor: "status_meaning"},
    { Header: "Financial ID", accessor: "financial_id" },
    { Header: "Invoice Financial ID", accessor: "inv_fin_id" },
    { Header: "Invoice Ref Number", accessor: "invoice_ref_number" },
    { Header: "Financial Transaction Concept", accessor: "fin_transaction_concept"},
    { Header: "Invoice Financial Concept", accessor: "invoicefin_transaction_concept"},
    { Header: "Invoice Reference", accessor: "invreference"},
    { Header: "Transaction Date Time", accessor: "transaction_datetime"}
  ];

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Transaction Statuses
          </Box>
        </Flex>
      </Flex>

      <Box py={4}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/transaction/report`}
          tokenKey="jwt_access_token"
          downloadFunc={downloadTransactionMapReport}
          handleDownloadFunc={() => {}}
          showActions={false}
        />
      </Box>
    </AdminAppShell>
  );
};
