import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

type ExportFormat = 'csv' | 'xlsx' | 'pdf';

interface IDataToExport {
  data: any[]; // Assuming an array of objects
  fileName: string;
}

export const exportData = async ({ data, fileName }: IDataToExport, format: ExportFormat) => {
  switch (format) {
    case 'csv': {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const csvOutput = XLSX.write(workbook, { bookType: 'csv', type: 'string' });
      const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `${fileName}.csv`);
      console.log(data, fileName)
      break;
    }
    case 'xlsx': {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const xlsxBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
      console.log(data, fileName)
      break;
    }
    case 'pdf': {
      const element = document.getElementById('element-to-convert-to-pdf'); // Ensure you have an element with this ID
      if (element) {
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/png');
        const pdf: any = new jsPDF();
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save(`${fileName}.pdf`);
      }
      console.log(data, fileName)
      break;
    }
    default:
      console.log('Unsupported format');
  }
};
