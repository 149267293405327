import { Box, Flex } from '@chakra-ui/react'
import { PcCaseIcon } from 'lucide-react'
import React from 'react'
import DataTableThree from '../../../components/DataTableThree'
import AdminAppShell from '../../../layouts/AdminAppShell'

export const ReconciliationStatus = () => {

  const columns = [
    { Header: "GUID", accessor: "guid" },
    { Header: "Start Date", accessor: "start_from"},
    { Header: "Rows", accessor: "rows"},
    { Header: "Return Code", accessor: "return_code"},
    { Header: "Message", accessor: "message"}
  ]

  return (
    <AdminAppShell>
      <Flex alignItems="center" gap={2} mb={2}>
        <PcCaseIcon size={25} />
        <Box fontSize="xl" fontWeight="bold">
          Reconsiliation Status Records
        </Box>
      </Flex>

      <Box py={4}>
      <DataTableThree
             columns={columns}
             fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_recon_statement`}
             tokenKey="jwt_access_token"
             downloadFunc={() => {}}
             handleDownloadFunc={() => {}}
             showActions={false}
        />
        </Box>
    </AdminAppShell>
  )
}
