import Datatable from "../../components/DataTable";
import AdminAppShell from "../../layouts/AdminAppShell";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Box,
  useDisclosure,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Spinner,
  useToast,
  Checkbox,
  Select,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { PcCaseIcon } from "lucide-react";
import React, { useState } from "react";
import { useStore } from "../../hooks";
import { useEffect } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const initialValues = {
  name: "",
  make: "",
  model: "",
  type: "",
  mac_address: "",
  serialno: "",
  enabled: false,
};

const deviceTypes = [
  {
    id: 1,
    title: "agent",
  },
  {
    id: 2,
    title: "driver",
  },
  {
    id: 3,
    title: "validator",
  },
];

const deviceModels = [
  {
    id: 1,
    title: "P8",
  },
  {
    id: 2,
    title: "TPS530",
  },
];

// const validate = (values: any) => {
//   const errors: any = {};

//   if (!values.name) {
//     errors.name = "Name is required";
//   }

//   if (!values.make) {
//     errors.make = "Make is required";
//   }

//   if (!values.model) {
//     errors.model = "Model is required";
//   }

//   if (!values.type) {
//     errors.type = "Type is required";
//   }

//   if (!values.mac_address) {
//     errors.mac_address = "Mac Address is required";
//   }

//   // if (!values.serialno) {
//   //   errors.serialno = "Serial Number is required";
//   // }

//   return errors;
// };

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  make: Yup.string().required("Make is required"),
  model: Yup.string().required("Model is required"),
  type: Yup.string().required("Type is required"),
  // mac_address: Yup.string().required('Mac Address is required'),
  serialno: Yup.string().required("Serial Number is required"),
  enabled: Yup.boolean().required("Enabled is required"),
});

export default function Devices() {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const fetchDevices = useStore((state) => state.fetchDevices);
  const createDevices = useStore((state) => state.createDevices);
  const removeDevices = useStore((state) => state.removeDevices);
  const updatedDevices = useStore((state) => state.updateDevices);
  const posDevices = useStore((state) => state.devices);
  const isLoading = useStore((state) => state.loading);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [device, setDevice] = useState<any>(null);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  const handleSubmit = (userDetails: any) => {
    setLoading(true);

    if (!userDetails.enabled) {
      userDetails.enabled = false;
    }

    userDetails.mac_address = userDetails.serialno;
    createDevices(userDetails).then((res: any) => {
      if (res.name) {
        // TODO: PLACE A NOTIFICATION HERE FOR SUCCESS
        setLoading(false);
        onClose();

        toast({
          title: "Success",
          description: "Device was added successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        // redirect to a page that has the licensing feature 
        navigate(`/admin/licenses?deviceId=${res.id}`)

        // fetchDevices();
        
      } else {
        // TODO: PLACE A NOTIFICATION HERE FOR ERRORS
        setLoading(false);
      }
    });
  };

  const submitUpdate = (updatedData: any) => {
    setLoading(true);

    if (!updatedData.enabled) {
      updatedData.enabled = false;
    }

    updatedData.mac_address = updatedData.serialno;
    updatedDevices(updatedData).then((res: any) => {
      if (!_.isEmpty(res)) {
        setLoading(false);
        onClose();
        fetchDevices();
        toast({
          title: "Success",
          description: "Device was updated successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleUpdate = (item: any) => {
    const newPayloadFormat: any = {
      id: item.id,
      name: item.name,
      make: item.make,
      model: item.model,
      type: item.type,
      mac_address: item.mac_address,
      serialno: item.serialno,
      enabled: item.enabled,
    };

    setDevice(newPayloadFormat);

    if (device) {
      onOpen();
      console.log(device);
    }

    // launch the modal and update the Form heading
  };

  const handleViewMore = (item: any) => {
    navigate(`/admin/devices/${item?.id}`);
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Devices
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setDevice(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create Device
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
          {overlay}
          <ModalContent>
            <ModalHeader>
              {device ? "Update Existing Device" : "Create a New Device"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={device ? device : initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                validateOnChange={true}
                onSubmit={device ? submitUpdate : handleSubmit}
              >
                <Form>
                  <Stack direction="column" spacing={6}>
                    <Field name="name">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <FormLabel>
                            Name <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input {...field} type="name" id="name" />
                          <FormErrorMessage>
                            {form.errors.name &&
                              form.touched.name &&
                              form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="make">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.make && form.touched.make}
                        >
                          <FormLabel>
                            Make <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="Select a device make"
                            type="model"
                            id="model"
                          >
                            <option value={"Telco"}>Telco</option>
                          </Select>
                          <FormErrorMessage>
                            {form.errors.make &&
                              form.touched.make &&
                              form.errors.make}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="model">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.model && form.touched.model}
                        >
                          <FormLabel>
                            Model <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="Select a device model"
                            type="model"
                            id="model"
                          >
                            {deviceModels &&
                              deviceModels.map((item: any, index: number) => (
                                <option key={index} value={item.title}>
                                  {item.title}
                                </option>
                              ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.model &&
                              form.touched.model &&
                              form.errors.model}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="type">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.type && form.touched.type}
                        >
                          <FormLabel>
                            Type <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="Select a device type"
                            type="type"
                            id="type"
                          >
                            {deviceTypes &&
                              deviceTypes.map((item: any, index: number) => (
                                <option key={index} value={item.title}>
                                  {item.title}
                                </option>
                              ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.type &&
                              form.touched.type &&
                              form.errors.type}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    {/* <Field name="mac_address">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.mac_address && form.touched.mac_address}
                        >
                          <FormLabel>Mac Address</FormLabel>
                          <Input {...field} type="mac_address" id="mac_address" />
                          <FormErrorMessage>
                            {form.errors.mac_address &&
                              form.touched.mac_address &&
                              form.errors.mac_address}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field> */}

                    <Field name="serialno">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.serialno && form.touched.serialno
                          }
                        >
                          <FormLabel>
                            Serial Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input {...field} type="serialno" id="serialno" />
                          <FormErrorMessage>
                            {form.errors.serialno &&
                              form.touched.serialno &&
                              form.errors.serialno}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="enabled">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.enabled && form.touched.enabled
                          }
                        >
                          <FormLabel>
                            Enabled <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Checkbox
                            isChecked={field.value}
                            {...field}
                            colorScheme="teal"
                            size="lg"
                            iconColor="red.500"
                            type="enabled"
                            id="enabled"
                          >
                            Enabled
                          </Checkbox>
                          <FormErrorMessage>
                            {form.errors.enabled &&
                              form.touched.enabled &&
                              form.errors.enabled}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Stack direction="row" spacing={4}>
                      <Button
                        width="100%"
                        colorScheme="teal"
                        variant="solid"
                        type="submit"
                      >
                        {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </Formik>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              Header: "Name",
              accessorKey: "name",
            },
            {
              Header: "Make",
              accessorKey: "make",
            },
            {
              Header: "Enabled",
              accessorKey: "enabled",
            },
            {
              Header: "Model",
              accessorKey: "model",
            },
            {
              Header: "Type",
              accessorKey: "type",
            },
            // {
            //   Header: "Mac Address",
            //   accessorKey: "mac_address",
            // },
            {
              Header: "Serial No",
              accessorKey: "serialno",
            },
          ]}
          data={posDevices}
          dataTableHeading={"Devices"}
          handleDelete={removeDevices}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
}
