import React, { useEffect } from "react";
import AdminAppShell from "../../../layouts/AdminAppShell";
import { useParams, useNavigate } from "react-router-dom";
import { useStore } from "../../../hooks";
import { Box, Button, Card, CardBody, Flex, Heading, Input, Spacer } from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";

const Role = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleRole = useStore((state) => state.fetchSingleRole);
  const posRole = useStore((state) => state.role);

  useEffect(() => {
    if (id) {
      fetchSingleRole({ id: id });
    }
  }, [fetchSingleRole, id]);

  return (
    <AdminAppShell>
      <Flex>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Role - {posRole ? posRole?.title : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box>
        <Card align={"center"}>
          <CardBody>
            <Flex direction={"column"} gap={"3"} justifyContent={"center"}>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Title:{" "}
                </Heading>
                <Input readOnly py="2" value={posRole?.title} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Description:{" "}
                </Heading>
                <Input readOnly py="2" value={posRole?.description} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Created:{" "}
                </Heading>
                <Input readOnly py="2" value={posRole?.created_ts} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Updated:{" "}
                </Heading>
                <Input readOnly py="2" value={posRole?.updated_ts} />
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </AdminAppShell>
  );
};

export default Role;
