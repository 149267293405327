import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    Spacer,
  } from "@chakra-ui/react";
  import React from "react";
  import { useColorMode } from "@chakra-ui/react";
  import { MoonIcon, SunIcon } from "@chakra-ui/icons";
  
  export default function ThemeShell({ children }: any) {
    const { colorMode, toggleColorMode } = useColorMode();
  
    return (
      <div>
        <Flex minWidth="max-content" alignItems="center" gap="2">
          <Spacer />
          <ButtonGroup gap="2" p="4">
            <Button onClick={toggleColorMode}>
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </Button>
          </ButtonGroup>
        </Flex>
        <Box>{children}</Box>
      </div>
    );
  }
  