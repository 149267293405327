import React, { useEffect } from "react";
import { useReconStore, useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Flex,
  Heading,
  Box,
  Input,
  Spacer,
  Button,
  Text,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import DataTableThree from "../../../components/DataTableThree";
import { access } from "fs";

const Operator: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleOperator = useStore((state) => state.fetchSingleOperator);
  const downloadPTOTripDetails = useReconStore((state) => state.downloadPTOTripDetails);
  const posOperator = useStore((state) => state.operator);
  const fetchOperatorDeviceInfo = useStore(
    (state) => state.fetchOperatorDeviceInfo
  );
  const operatorInfo = useStore((state) => state.operatorInfo);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = [
    { Header: "Operator Name", accessor: "OperatorName" },
    {Header: "Trip ID", accessor: "TripID"},
    {Header: "Number Of Passengers", accessor:"NumberOfPassengers"},
    {Header: "Route Used", accessor:  "RouteUsed"},
    {Header: "Driver", accessor: "Driver"},
    {Header: "Tariff", accessor: "Tariff"},
    {Header: "Total Fare Collected", accessor: "TotalFareCollected"},
    {Header: "Trip Start Time", accessor: "TripStartTime"},
    {Header: "TripEndTime", accessor: "TripEndTime"},
  ]

  useEffect(() => {
    if (id) {
      fetchSingleOperator({ id: id });
      fetchOperatorDeviceInfo({ operator_id: id });
    }
  }, [fetchSingleOperator, id, fetchOperatorDeviceInfo]);

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Operator - {posOperator ? posOperator?.name : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box marginY={"8"}>
        <Card>
          <CardBody>
            <Flex justifyContent={"space-between"} gap={"1rem"}>
              <Box w={"50%"}>
                <Flex direction={"column"} gap={"3"} justifyContent={"center"}>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Name:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.name} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Code:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.code} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Address:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.address} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Contact Person:{" "}
                    </Heading>

                    <Input
                      readOnly
                      py="2"
                      value={posOperator?.contact_person}
                    />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Contact Number:{" "}
                    </Heading>

                    <Input
                      readOnly
                      py="2"
                      value={posOperator?.contact_number}
                    />
                  </Flex>
                </Flex>
                <Flex direction={"column"} gap={"3"} justifyContent={"center"}>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Total Vehicles:{" "}
                    </Heading>

                    <Input
                      readOnly
                      py="2"
                      value={posOperator?.total_vehicles}
                    />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Total Agents:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.total_agents} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Created:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.created_ts} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Updated:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.updated_ts} />
                  </Flex>
                </Flex>
              </Box>
              <Box w={"50%"}>
                <Box>
                  {operatorInfo?.operator ? (
                    <>
                      <Text>
                        Operator{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {operatorInfo.operator.operator_name}
                        </span>{" "}
                        has been connected to the devices below:
                      </Text>
                      <Box display={"flex"} flexDirection={"column"}>
                        {operatorInfo?.operator.devices.map(
                          (device: any, index: number) => (
                            <Badge
                              key={index}
                              variant="solid"
                              colorScheme="green"
                              margin={"4px"}
                              width={"auto"}
                            >
                              {device.device_name}
                            </Badge>
                          )
                        )}
                      </Box>
                    </>
                  ) : (
                    <Text>
                      {operatorInfo?.message || "Operator info not available"}
                    </Text>
                  )}
                </Box>
                <Box mt={8}>
                  <Text fontWeight={'extrabold'}>Other Actions: </Text>
                  <Button mt={2} w={'full'} colorScheme="green" onClick={onOpen} >
                    View Trips Made
                  </Button>

                  <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>
                        Trips Made by {posOperator?.name}
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <DataTableThree
                          columns={columns}
                          fetchUrl={`${process.env.REACT_APP_BASE_URL}/operators/${id}/trip-details`}
                          tokenKey="jwt_access_token"
                          downloadFunc={downloadPTOTripDetails}
                          handleDownloadFunc={() => {}}
                          showActions={false}
                          id={id}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button colorScheme="green" mr={3} onClick={onClose}>
                          Close
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </AdminAppShell>
  );
};

export default Operator;
