import { Box, Button, Card, CardBody, Flex, Heading, Input, Spacer, Text } from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

const Location = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleLocation = useStore((state) => state.fetchSingleLocation);
  const posLocation = useStore((state) => state.location);

  useEffect(() => {
    if (id) {
      fetchSingleLocation({ id: id });
    }
  }, [fetchSingleLocation, id]);

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Location - {posLocation ? posLocation?.name : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box marginY={'8'}>
      <Card align="center">
          <CardBody>
          <Flex direction={'column'} gap={'3'} justifyContent={'center'}>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Name: </Heading>
                <Input readOnly py="2" value={posLocation?.name}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Longitude: </Heading>
                <Input readOnly py="2" value={posLocation?.longitude}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Latitude: </Heading>
                <Input readOnly py="2" value={posLocation?.latitude}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Type: </Heading>
                <Input readOnly py="2" value={posLocation?.type}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Facility: </Heading>
                <Input readOnly py="2" value={posLocation?.facility}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Created: </Heading>
                <Input readOnly py="2" value={posLocation?.created_ts}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Updated: </Heading>
                <Input readOnly py="2" value={posLocation?.updated_ts}/>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </AdminAppShell>
  );
};

export default Location;
