import React from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import DataTableThree from "../../components/DataTableThree";
import { useReconStore } from "../../hooks";

export const ViewTransactionExceptions = () => {
  const downloadDataFunc = useReconStore(
    (state) => state.downloadProcessingFeeData
  );

  const columns = [
    { Header: "TXN GUID", accessor: "txn_guid" },
    { Header: "Transaction DateTime", accessor: "transaction_datetime" },
    { Header: "External Sync Status", accessor: "external_sync_status" },
    { Header: "External Sync TS", accessor: "external_sync_ts" },
    { Header: "Remarks", accessor: "remarks" },
    { Header: "Total Amount", accessor: "total_amount" },
  ];

  const handleEdit = (item: any) => {
    console.log("Edit clicked for item:", item);
    // Add your logic to handle editing the item
  };

  const handleResubmit = (item: any) => {
    console.log("Resubmit clicked for item:", item);
    // Add your logic to handle resubmitting the item
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            View Transaction Exceptions
          </Box>
        </Flex>
        <Spacer />
      </Flex>

      <Box py={6}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/transactions-exceptions`}
          tokenKey="jwt_access_token"
          handleDownloadFunc={() => {}}
          downloadFunc={downloadDataFunc}
          showActions={true}
          showEditButton={true} // Enable the Edit button
          showResubmitButton={true} // Enable the Resubmit button
          handleEdit={handleEdit} // Pass the edit handler
          handleResubmit={handleResubmit} // Pass the resubmit handler
          showIcons={false}
        />
      </Box>
    </AdminAppShell>
  );
};
