const operatorsData: any  = [
    {
        id: "96504ffa-3e42-4aae-9653-b7cec7ed7e9f",
        name: "pto01",
        code: "faipaypto",
        address: "2/3 ,road#9,hyd,TG",
        contact_person: "PERSON1",
        contact_number: "1233445567",
        contact_email: "test@gmail.com",
        license_number: "LICENSENO_01",
        total_vehicles: "10",
        total_agents: "10",
        enabled: true
    }
]

export default operatorsData
